import { styled } from 'styled-components';
import { FmmTheme } from '../components/theme';

export const LoginStyle = styled.div`
  width: 100%;
  .loginWrapper {
    width: 100vw;
    height: 100vh;
    display: flex;

    .graphicsWrapper {
      width: 50%;
      padding: 64px;
      position: relative;

      .background {
        width: 100%;
        height: calc(100% + 0.5rem);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -99;
        overflow: hidden;
        margin-top: -0.5rem;

        picture {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      picture {
        margin-top: 4px;
      }

      .text {
        width: 100%;
        max-width: 508px;
        height: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;

        p {
          font-size: 24px;
          line-height: 32px;
          font-weight: 400;
          color: ${FmmTheme.colors.text.white};
          font-style: normal;
        }
        .clientName {
          font-size: 20px;
          font-weight: 500;
        }
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
    .formWrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px;
      @media (min-width: 767px) {
        justify-content: center;
      }
      .form {
        width: 100%;
        max-width: 504px;

        h2 {
          color: ${FmmTheme.colors.text.header};
          font-size: ${FmmTheme.headerSize.xs};
          margin-bottom: 2rem;
        }

        .inputGroup {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          margin-bottom: 1rem;
        }

        .rememberMeGroup {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 2rem;

          .rememberMe {
            color: ${FmmTheme.colors.text.body};
            height: 100%;
          }
          .forgotPassword {
            font-size: 14px;
            p {
              cursor: pointer;
              padding: 0;
              margin: 0;
            }
          }
        }

        .buttonGroup {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 2rem;
        }

        .signUp {
          width: 100%;
          display: flex;
          gap: 1rem 0.5rem;
          flex-wrap: wrap;
          margin: 0;

          .createAccount {
            color: ${FmmTheme.colors.text.body};
            margin: 0;
          }

          .signUpLink {
            color: ${FmmTheme.colors.text.primary};
            position: relative;
            white-space: nowrap;
            cursor: pointer;
            margin: 0;
          }
          .sigUpVector {
            img {
              position: absolute;
              bottom: -0.5rem;
              right: 0;
            }
          }
        }

        .logo {
          margin-bottom: 2.5rem;
          width: 100%;

          @media (min-width: 768px) {
            display: none;
          }
        }
      }

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .password {
      position: relative;
      .showPassword {
        position: absolute;
        right: 1rem;
        top: 2.8rem;
        cursor: pointer;
        font-size: 1.25rem;
        &:hover {
          color: ${FmmTheme.colors.primary};
        }
      }
    }
    .mb1rem {
      margin-bottom: 1rem;
    }
    .mb2rem {
      margin-bottom: 2rem;
    }
    .mb3rem {
      margin-bottom: 3rem;
    }
  }
`;
