import { styled } from 'styled-components';
import { FmmTheme } from '../theme';

export const SignUpCompStyle = styled.div`
  width: 100%;
  .signupCompWrapper {
    width: 100%;
    max-width: 500px;

    .sectionTitle {
      margin: 2rem 0;
      h2 {
        color: ${FmmTheme.colors.text.header};
        font-size: ${FmmTheme.headerSize.xs};
        margin-bottom: 0.5rem;
      }
      p {
        color: ${FmmTheme.colors.text.body};
        font-size: ${FmmTheme.textSize.l};
      }
    }

    .otpInputWrapper {
      width: 100%;
      max-width: 400px;
      .label {
        color: ${FmmTheme.colors.text.header};
        font-weight: 600;
        margin-bottom: 1rem;
      }
      .OTP {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0 2rem;
        margin-bottom: 1rem;

        .otpField {
          width: clamp(3rem, 5vw, 4rem);
          height: clamp(3rem, 5vw, 3.5rem);
          border-radius: 0.75rem;
          text-align: center;
          border: 1px solid ${FmmTheme.colors.text.accent};
          color: ${FmmTheme.colors.text.accent};
          font-size: 1.5rem;
          font-weight: 500;
          &::placeholder {
            color: ${FmmTheme.colors.text.accent};
          }
        }
      }
    }

    .changeEmailWrapper {
      margin: 2rem 0;

      .login {
        width: 100%;

        .haveAccount {
          color: ${FmmTheme.colors.text.body};
        }

        .loginLink {
          color: ${FmmTheme.colors.text.primary};
          position: relative;
          white-space: nowrap;
          font-weight: 600;
          cursor: pointer;

          .signUpVector {
            width: 100%;
            img {
              position: absolute;
              bottom: -0.75rem;
              right: 0.5rem;
            }
          }
        }
      }
    }
  }
`;
