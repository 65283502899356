import React, { useEffect, useState } from 'react';
import { SignUpCompStyle } from './style';
import { FmmTheme } from '../theme';
import { AuthPageProps } from '../../pages/signup';
import { FmmButton } from '../button';
import { Post, useLoginRouter } from '../../utilities/useApi';
import { notification } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { auth } from '../../redux/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useRouter } from 'next/router';
import { setUser } from '@sentry/nextjs';
import { UserProps } from '../../pages/login';

function FMMOTPPage({
  setPage,
  user,
  resetPasswordOtp,
}: {
  setPage: React.Dispatch<React.SetStateAction<AuthPageProps>>;
  user?: any;
  resetPasswordOtp?: boolean;
}) {
  const [filled, setFilled] = useState(false);
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [processing, setProcessing] = useState<boolean>(false);

  const { token } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const router = useRouter();

  const handleChange = (element: any, index: any) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const callbackUrl = localStorage.getItem('callbackUrl');

  useEffect(() => {
    if (otp[3]) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [otp]);

  const handleVerifyAccount = async (event: any) => {
    event.preventDefault();
    setProcessing(true);
    if (filled) {
      const userOTP = otp.join('');
      Post({
        url: '/userservice/verify-account',
        data: {
          otp: userOTP,
          otpId: user?.otpId,
        },
        token,
      })
        .then((data) => {
          useLoginRouter({ role: data.response.user.role, accessToken: token });
          dispatch(auth({ user: data.response.user, token }));
          notification.open({
            message: 'Account created',
            description: data.response.verification,
            icon: <SmileOutlined style={{ color: 'green' }} />,
          });
          setProcessing(false);
          router.push(callbackUrl ?? '/');
        })
        .catch((err) => {
          notification.open({
            message: 'Error',
            description: err.message,
            icon: <SmileOutlined style={{ color: 'red' }} />,
          });
          setProcessing(false);
        });
    } else {
      notification.error({
        message: 'enter the otp sent to your email',
      });
      setProcessing(false);
    }
  };

  const handleVerifyOTP = (e: any) => {
    e.preventDefault();
    let pin = otp.join('');
    Post({
      url: '/userservice/verify-otp',
      data: {
        otp: pin,
        otpId: user.otpId,
      },
    })
      .then((data) => {
        if (data?.data !== '') {
          notification.open({
            message: 'Successful',
            description: 'Successful. Input your new password',
            icon: <SmileOutlined style={{ color: 'green' }} />,
          });
          setUser((prev: UserProps) => ({ ...prev, token: data.token }));
          setPage({ form: false, forgotPassword: false, otp: false, changePassword: true });
        }
      })
      .catch((err) => {
        if (err) {
          notification.open({
            message: 'Error',
            description: 'OTP is expired or not valid, please try again',
            icon: <SmileOutlined style={{ color: 'red' }} />,
          });
        }
      });
  };

  return (
    <SignUpCompStyle>
      <div className='signupCompWrapper'>
        <div className='sectionTitle'>
          <h2>Confirm Email</h2>
          <p>
            We sent an OTP to{' '}
            <span style={{ color: '#008080' }}>{` ${user?.email && user?.email} `}</span>{' '}
            to confirm you are the owner of the email address you signed up
            with.
          </p>
        </div>
        <form onSubmit={resetPasswordOtp ? handleVerifyOTP : handleVerifyAccount}>
          <div className='otpInputWrapper'>
            <label htmlFor='otp' className='label'>
              Enter OTP PIN
            </label>
            <div className='OTP'>
              {otp.map((data, index) => {
                return (
                  <input
                    className='otpField'
                    placeholder='_'
                    type='number'
                    name='otp'
                    maxLength={1}
                    key={index}
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                    required
                  />
                );
              })}
            </div>
          </div>
          <FmmButton
            text={processing ? 'processing...' : 'Confirm'}
            height='44px'
            backgroundColor={processing ? '#fff' : undefined}
            border={processing ? `1px solid ${FmmTheme.colors.border1}` : undefined}
            color={processing ? FmmTheme.colors.border1 : '#fff'}
          />
          {resetPasswordOtp &&
            <div style={{ marginTop: '1rem' }}>
              <FmmButton
                text='Cancel'
                height='44px'
                border={`1px solid ${FmmTheme.colors.border1}`}
                onClick={() => setPage({ form: false, forgotPassword: true, otp: false })}
                color={FmmTheme.colors.text.header}
                backgroundColor={FmmTheme.colors.text.white}
              />
            </div>
          }
        </form>

        {!resetPasswordOtp && <div className='changeEmailWrapper'>
          <div className='login'>
            <p className='haveAccount'>
              Wrong email address? &nbsp;{' '}
              <span
                className='loginLink'
                onClick={() =>
                  setPage({ form: false, otp: false, changeEmail: true })
                }
              >
                <>Update it here</>
                <picture className='signUpVector'>
                  <source srcSet='/images/change-mail.svg' type='image/webp' />
                  <img src='/images/change-mail.svg' alt='' />
                </picture>
              </span>
            </p>
          </div>
        </div>}
      </div>
    </SignUpCompStyle>
  );
}

export default FMMOTPPage;
