
import { FmmInput, FmmSubmitButton } from '../components/forms/components';
import { Formik } from 'formik';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import * as yup from 'yup';
import { FcGoogle } from 'react-icons/fc';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import FMMForgotPasswordPage from '../components/login/forgotPasswordPage';
import { AuthPageProps } from './signup';
import { AppHead, FmmTheme } from '../components/theme';
import { FmmButton } from '../components/button';
import { LoginStyle } from '../styles/login2';
import { notification } from 'antd';
import { Get, Post, useLoginRouter } from '../utilities/useApi';
import { auth } from '../redux/slices/authSlice';
import { useDispatch } from 'react-redux';
import FMMOTPPage from '../components/signup/otpComponent';
import FMMChangePasswordPage from '../components/login/changePassword';

export type UserProps = {
  otpId?: string;
  email?: string;
  token?: string;
};

const validationSchema = yup.object().shape({
  email: yup.string().required('email is required'),
  password: yup.string().required('password is required'),
});


function Login() {
  const router = useRouter();
  const [processing, setProcessing] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [page, setPage] = useState<AuthPageProps>({ form: true, forgotPassword: false, otp: false, changePassword: false });
  const [user, setUser] = useState<UserProps>({
    otpId: '',
    email: '',
  });
  const [changeOTP, setChangeOTP] = useState<boolean>(false);
  const dispatch = useDispatch();
  const callbackUrl = localStorage.getItem('callbackUrl');

  return (
    <LoginStyle>
      <AppHead pageTitle='FMM | Login' />
      <div className='loginWrapper'>
        <div className="graphicsWrapper">
          <div className="background">
            <picture>
              <source srcSet='/login/background.webp' type='image/webp' />
              <img src='/login/background.webp' alt="" />
            </picture>
          </div>
          <picture onClick={() => router.push('/')}>
            <source srcSet='/login/logo.svg' type='image/webp' />
            <img src="/login/logo.svg" alt="Filmmakers Mart" />
          </picture>
          <div className="text">
            <p>"Filmmakers Mart is very involved and present throughout the entire production and as such, the client doesn't feel alone. Your team does magic."</p>
            <p className='clientName'>- Ifeoluwa, Filmtrybe</p>
          </div>
        </div>

        <div className="formWrapper" >
          <div className="form">
            <div className="logo">
              <picture onClick={() => router.push('/')}>
                <source />
                <img src="/fmm-logo-black.svg" alt="Filmmakers Mart" />
              </picture>
            </div>
            {page.form && <>
              <h2>Welcome Back</h2>
              <Formik
                validationSchema={validationSchema}
                initialValues={{
                  email: '',
                  password: '',
                }}
                onSubmit={async (values: any, { resetForm }) => {
                  setProcessing(true);
                  Post({
                    url: '/auth/login',
                    data: {
                      email: values.email,
                      password: values.password,
                    },
                  })
                    .then((res) => {
                      dispatch(auth({ user: res, token: res.accessToken }));
                      resetForm();
                      setProcessing(false);
                      if (!res.isVerified) {
                        Get({
                          url: '/userservice/verification/resend-code', token: res.accessToken, data: { otpId: user?.otpId },
                        })
                          .then((otpRes: any) => {
                            notification.success({
                              message: 'Otp sent',
                              description: 'You account has not been verified, an otp has been sent to your email',
                            });
                            setUser({ ...user, otpId: otpRes.data.otpId, email: otpRes.data.user.email });
                            setPage({ otp: true, form: false });
                          })
                          .catch(() => {
                            notification.error({
                              message: 'Error',
                              description: 'An error occurred, please try again',
                            });
                          });

                      } else {
                        notification.success({
                          message: 'User logged in',
                          description: `Welcome, ${res.firstName}`,
                        });
                        useLoginRouter({ role: res.role, accessToken: res.accessToken });
                        router.push(callbackUrl ?? '/');
                      }

                    })
                    .catch((error) => {
                      let message = error.response
                        ? error.response.data.message
                        : error.message || error;
                      if (message === 'Network Error') message = 'check your internet connection';
                      setProcessing(false);
                      notification.error({
                        message: 'An error occurred',
                        description: message,
                      });
                    });
                }}
              >
                {
                  (props) => (
                    <>
                      <div className="inputGroup">
                        <FmmInput
                          label='Email'
                          placeholder='Enter your email'
                          value={props.values.email}
                          field={'email'}
                          type={'email'}
                          props={props}
                          theme={FmmTheme}
                        />

                        <div className="password">
                          <FmmInput
                            label='Password'
                            placeholder='Enter your password'
                            value={props.values.password}
                            field={'password'}
                            type={showPassword ? 'text' : 'password'}
                            props={props}
                            theme={FmmTheme}
                          />
                          <div className="showPassword" onClick={() => setShowPassword(!showPassword)}>
                            {!showPassword ?
                              <VscEyeClosed /> : <VscEye />
                            }
                          </div>
                        </div>
                      </div>

                      <div className='rememberMeGroup'>
                        {/* <Checkbox className='rememberMe'>Remember me</Checkbox> */}
                        <p className='forgotPassword'
                          style={{ color: '#008080', cursor: 'pointer' }}
                          onClick={() => setPage({ form: false, forgotPassword: true })}
                        >Forgot Password</p>
                      </div>

                      <div className="buttonGroup">
                        <FmmSubmitButton buttonState={{ processing, setProcessing }} props={props} theme={FmmTheme} title="Login " />
                        <FmmButton
                          text='Sign in with Google'
                          icon={<FcGoogle size={18} />}
                          height='44px'
                          border={`1px solid ${FmmTheme.colors.border1}`}
                          onClick={() => router.push('https://users-api-playground.filmmakersmart.com/v2/users/auth/google')}
                          color={FmmTheme.colors.text.header}
                          backgroundColor={FmmTheme.colors.text.white}
                        />
                      </div>

                      <div className="signUp">
                        <p className='createAccount' >Don&apos;t have an account?</p>
                        <span className='signUpLink' onClick={() => router.push('/signup')}>
                          <>Sign Up</>
                          <picture className='sigUpVector'>
                            <source srcSet='/login/sign-up.svg' type='image/webp' />
                            <img src="/login/sign-up.svg" alt="" />
                          </picture>
                        </span>
                      </div>

                    </>
                  )
                }
              </Formik>
            </>}
            {page.forgotPassword &&
              <FMMForgotPasswordPage setPage={setPage} setUser={setUser} />
            }
            {page?.otp && (
              <FMMOTPPage
                setPage={setPage}
                user={user}
                resetPasswordOtp={changeOTP}
              />
            )}
            {page?.changePassword && <FMMChangePasswordPage setPage={setPage} user={user} setChangeOTP={setChangeOTP} />}
          </div>
        </div>
      </div>
    </LoginStyle >
  );
}

export default Login;
