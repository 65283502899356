import React, { useState } from 'react';
import { SignUpCompStyle } from './style';
import { BsArrowLeftShort } from 'react-icons/bs';
import { Formik } from 'formik';
import { FmmInput, FmmSubmitButton } from '../forms/components';
import * as yup from 'yup';
import { AuthPageProps } from '../../pages/signup';
import { FmmTheme } from '../theme';
import { FmmButton } from '../button';
import { Post } from '../../utilities/useApi';
import { notification } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { UserProps } from '../../pages/login';

const validationSchema = yup.object().shape({
  email: yup.string().required('this field is required'),
});

function FMMForgotPasswordPage({ setPage, setUser }: { setPage: React.Dispatch<React.SetStateAction<AuthPageProps>>, setUser: React.Dispatch<React.SetStateAction<UserProps>> }) {
  const [processing, setProcessing] = useState<boolean>(false);

  return (
    <SignUpCompStyle>
      <div className="signupCompWrapper">
        <div className="backButton" onClick={() => setPage({ form: true, forgotPassword: false })}><BsArrowLeftShort /></div>
        <div className="sectionTitle">
          <h2>Forgot Password</h2>
        </div>
        <div className="signupFormWrapper">
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              email: '',
            }}
            onSubmit={async (values: { email: string }, { resetForm }) => {
              setProcessing(true);
              Post({
                url: '/userservice/forget-password',
                data: {
                  email: values.email,
                },
              }).then(data => {
                if (data?.data !== '') {
                  notification.open({
                    message: 'Successful',
                    description: 'Check your email for OTP pin and it expires in 10mins',
                    icon: <SmileOutlined style={{ color: 'green' }} />,
                  });
                  setUser({
                    otpId: data.otpId,
                    email: values.email,
                  });
                  resetForm();
                  setPage({ form: false, forgotPassword: false, otp: true, changePassword: false });
                }
              })
                .catch(err => {
                  notification.open({
                    message: 'Error',
                    description: err.message,
                    icon: <SmileOutlined style={{ color: 'red' }} />,
                  });
                });
              setProcessing(false);
            }}
          >
            {(props) => (
              <>
                <div className='inputGroup' style={{ marginBottom: '2rem' }}>
                  <FmmInput
                    label='Email Address'
                    placeholder={'enter your email'}
                    value={props.values?.email}
                    field={'email'}
                    type={'email'}
                    props={props}
                    theme={FmmTheme}
                  />
                </div>
                <div className='buttonGroup' style={{ gap: '1rem' }}>
                  <FmmSubmitButton
                    buttonState={{ processing, setProcessing }}
                    props={props}
                    theme={FmmTheme}
                    title='Confirm '
                  />
                  <FmmButton
                    text='Cancel'
                    height='44px'
                    border={`1px solid ${FmmTheme.colors.border1}`}
                    onClick={() => setPage({ form: true, forgotPassword: false })}
                    color={FmmTheme.colors.text.header}
                    backgroundColor={FmmTheme.colors.text.white}
                  />
                </div>
              </>
            )}
          </Formik>
        </div>


      </div>
    </SignUpCompStyle>
  );
}

export default FMMForgotPasswordPage;