import React, { useState } from 'react';
import { SignUpCompStyle } from './style';
import { Formik } from 'formik';
import { FmmInput, FmmSubmitButton } from '../forms/components';
import * as yup from 'yup';
import { AuthPageProps } from '../../pages/signup';
import { FmmTheme } from '../theme';
import { FmmButton } from '../button';
import { Post } from '../../utilities/useApi';
import { notification } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { UserProps } from '../../pages/login';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { useRouter } from 'next/router';

const validationSchema = yup.object().shape({
  password: yup.string().required('this field is required'),
  confirmPassword: yup.string().required('this field is required'),
});

function FMMChangePasswordPage({ setPage, user, setChangeOTP }: { setPage: React.Dispatch<React.SetStateAction<AuthPageProps>>, user: UserProps, setChangeOTP: React.Dispatch<React.SetStateAction<boolean>> }) {
  const [processing, setProcessing] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const router = useRouter();
  return (
    <SignUpCompStyle>
      <div className="signupCompWrapper">
        <div className="sectionTitle">
          <h2>Reset Password</h2>
        </div>
        <div className="signupFormWrapper">
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            onSubmit={async (values: { password: string, confirmPassword: string }, { resetForm }) => {
              setProcessing(true);
              Post({
                url: '/userservice/reset-password', data: {
                  password: values.password,
                  token: user.token,
                  otpId: user.otpId,
                  email: user.email,
                },
              })
                .then((data) => {
                  if (data?.data !== '') {
                    notification.open({
                      message: 'Successful',
                      description: 'You can login with your new password',
                      icon: <SmileOutlined style={{ color: 'green' }} />,
                    });
                    resetForm();
                    setChangeOTP(true);
                    setPage({ changePassword: false, form: true });
                  }
                })
                .catch((err) => {
                  if (err.data) {
                    notification.open({
                      message: 'Error',
                      description: 'This email has not been registered or network issue',
                      icon: <SmileOutlined style={{ color: 'red' }} />,
                    });
                    return router.push('/signup');
                  }
                });
              setProcessing(false);
            }}
          >
            {(props) => (
              <>
                <div className="password mb2rem">
                  <FmmInput
                    label='Password'
                    placeholder='Enter your new password'
                    value={props.values.password}
                    field={'password'}
                    type={showPassword ? 'text' : 'password'}
                    props={props}
                    theme={FmmTheme}
                  />
                  <div className="showPassword" onClick={() => setShowPassword(!showPassword)}>
                    {!showPassword ?
                      <VscEyeClosed /> : <VscEye />
                    }
                  </div>
                </div>
                <div className="password mb3rem">
                  <FmmInput
                    label='Confirm Password'
                    placeholder='Enter your password again'
                    value={props.values.confirmPassword}
                    field={'confirmPassword'}
                    type={showConfirmPassword ? 'text' : 'password'}
                    props={props}
                    theme={FmmTheme}
                  />
                  <div className="showPassword" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {!showConfirmPassword ?
                      <VscEyeClosed /> : <VscEye />
                    }
                  </div>
                </div>
                <div className='buttonGroup' style={{ gap: '1rem' }}>
                  <FmmSubmitButton
                    buttonState={{ processing, setProcessing }}
                    props={props}
                    theme={FmmTheme}
                    title='Confirm '
                  />
                  <FmmButton
                    text='Cancel'
                    height='44px'
                    border={`1px solid ${FmmTheme.colors.border1}`}
                    onClick={() => setPage({ form: true, changePassword: false })}
                    color={FmmTheme.colors.text.header}
                    backgroundColor={FmmTheme.colors.text.white}
                  />
                </div>
              </>
            )}
          </Formik>
        </div>


      </div>
    </SignUpCompStyle>
  );
}

export default FMMChangePasswordPage;